<template>
  <div class="auth-form-wrapper">
    <div class="flex justify-content-center auth-wrapper">
      <div class="card">
        <Toast/>

        <h5 class="text-center">Reset Password</h5>

        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <!-- Email -->
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope"/>
              <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
              <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
            </div>

            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
              class="p-error">{{ v$.email.required.$message.replace('Value', 'Email') }}
            </small>
          </div>

          <!-- Password -->
          <div class="field">
            <div class="p-float-label">
              <Password id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask>
                <template #header>
                  <h6>Pick a password</h6>
                </template>
                <template #footer="sp">
                  {{ sp.level }}
                  <Divider/>
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password*</label>
            </div>

            <span v-if="v$.password.$error && submitted">
              <span v-for="(error, index) of v$.password.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Password') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response"
              class="p-error">{{ v$.password.required.$message.replace('Value', 'Password') }}
            </small>
          </div>

          <!-- Confirm Password -->
          <div class="field">
            <div class="p-float-label">
              <Password id="confirmPassword" v-model="v$.confirmPassword.$model" :class="{'p-invalid':v$.confirmPassword.$invalid && submitted}" toggleMask />
              <label for="confirmPassword" :class="{'p-error':v$.confirmPassword.$invalid && submitted}">Confirm Password*</label>
            </div>

            <small
              v-if="(v$.confirmPassword.$invalid && submitted) || v$.confirmPassword.$pending.$response"
              class="p-error">{{ v$.confirmPassword.sameAs.$message.replace('Value', 'Confirm Password') }}
            </small>
          </div>

          <Button type="submit" label="Submit" class="mt-2"/>

          <back-to-login/>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AuthResource from "@/http/AuthResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import BackToLogin from "@components/auth/BackToLogin";

export default {
  setup: () => ({v$: useVuelidate()}),

  components: {
    BackToLogin,
  },

  data() {
    return {
      email: this.$route.query.email,
      password: '',
      confirmPassword: '',
      passwordToken: this.$route.query.token,
      submitted: false,
    }
  },

  validations() {
    return {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAs: sameAs(this.password, 'Password') },
    }
  },

  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      AuthResource.resetPassword({
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: this.passwordToken
      }).then(response => {
        this.$toast.add(toastParamBuilder.success(response.status));
        this.resetForm();
        this.$router.push({ name: 'login' });
      }).catch(error => {
        this.$toast.add(toastParamBuilder.error(error.message ? error.message : error.password));
      });
    },

    resetForm() {
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
      this.submitted = false;
    },
  }
}
</script>
